import React from 'react';
import AboutSection from '../../Components/About/About';
import AboutIntro from '../../Components/About/AboutIntro/AboutIntro';
import ImageLarge from '../../Assets/Images/tom_cole_pic_1.jpg';
import ImageLarge2 from '../../Assets/Images/tom_cole_pic_3.jpg';
import ImageSmall1 from '../../Assets/Images/tom_cole_pic_2.jpg';
import ImageSmall3 from '../../Assets/Images/tom_cole_pic_4.jpg';
import ImageSmall4 from '../../Assets/Images/tom_cole_pic_5.jpg';
import ImageSmall5 from '../../Assets/Images/tom_cole_pic_6.jpg';
import ImageSmall7 from '../../Assets/Images/tom_cole_pic_7.jpg';
import ImageSmall8 from '../../Assets/Images/tom_cole_pic_8.jpg';
import './About.css';


const About = (props) => {
    return (
        <div className="About Page" id="about">
            <AboutIntro 
                text={props.text} />
            <AboutSection
                ImageLarge={ImageSmall7}
                ImageSmall1={ImageSmall1}
                ImageSmall2={ImageLarge2}
                text={props.endorsement1}
                endorsement={props.endorsement1Name} />
             <AboutSection
                reverse
                ImageLarge={ImageLarge}
                ImageSmall1={ImageSmall4}
                ImageSmall2={ImageSmall8}
                text={props.endorsement2}
                endorsement={props.endorsement2Name} />
        </div>
    )
}

export default About;