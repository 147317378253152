import React from 'react';

class Video extends React.Component {

    render() {
        return (
            <div className="vidContainer">
                <div className='wrapper'>
                    <div className='col'>
                        <div id='shape2' className='shape'></div>
                        <h4 style={{position:'absolute'}}>{this.props.vidTitle}</h4>
                    </div>
                    <iframe width="100%" height="100%" src={this.props.vidUrl} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                   
                </div>
                {/* <div className="Video Page" id="video">
                    <div className="Container"> */}
                {/* <h3 className="light" style={{ textAlign: 'center', margin: '64px 0 0 0' }}>{}</h3> */}

                {/* </div>
                </div> */}
                {/* <div className="Extra Page">
                    
                </div> */}
            </div>
        )
    }

}

export default Video;