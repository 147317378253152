import React from 'react';
import './AlbumRelease.css';

const AlbumRelease = (props) => {

    return(
        <div className="AlbumRelease">
           <h6>{"Release Date: "+props.release}</h6>
        </div>
    )

}

export default AlbumRelease;