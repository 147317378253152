import React from 'react';
import './Navigation.css';

class Navigation extends React.Component {

    navigateHandler = (id) => {
        if(this.props.delegateNav){
            this.props.navigateHandler(id);
            return;
        }
        if(this.props.toggleDrawer){
            this.props.toggleDrawer();
        }
		document.getElementById(id).scrollIntoView();
    }
    
    render(){
        return(
            <div className={this.props.light ? "NavBar opp" : "NavBar"}>
                <div>
                    <ul>
                        <li><a onClick={() => this.navigateHandler('music')}>Music</a></li>
                        <li><a onClick={() => this.navigateHandler('events')}>Events</a></li>
                        <li><a onClick={() => this.navigateHandler('about')}>About</a></li>
                        <li><a onClick={() => window.open('https://tomcole.bandcamp.com/merch', '_blank')}>Store</a></li>
                        <li><a onClick={() => this.navigateHandler('contact')}>Contact</a></li>
                    </ul>
                </div>
            </div>
        )
    }

}

export default Navigation;