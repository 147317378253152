import React from 'react';
import { Link } from "react-router-dom";
import Social from '../Social/Social';
import Copyright from '../Copyright/Copyright';
import './Footer.css';

const Footer = () => {
    return (

        <div className="curveArea" style={{maxHeight: '300px'}}>
            <div className="mainBox" style={{backgroundColor: '#fff'}}>
                <div className="curveSection">
                    <div className="Container">
                        <div className="Footer">
                            <Link to={'tomcoleandhenrybristow'}>Tom Cole &amp; Henry Bristow Duo</Link>
                            <Social />
                            <Copyright />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;