import React from 'react';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { app } from '../../tools/firebase';
import axios from 'axios';
import './Login.css';
import { API_URL } from '../../tools/api';

class Login extends React.PureComponent {

    state = {
        user: "",
        password: "",
        fail: false,
        failText: ""
    }

    changeHandler = (event, type) => {
        if (type === "user") {
            this.setState({ user: event.target.value });
        } else {
            this.setState({ pass: event.target.value });
        }
    }

    attemptLogin = () => {
        const auth = getAuth();
        signInWithEmailAndPassword(auth, this.state.user, this.state.pass)
            .then((userCredential) => {
                this.props.loginHandler()
            })
            .catch((error) => {
                const errorCode = error.code;
                if (errorCode !== "auth/invalid-email" && errorCode !== "auth/invalid-credential") {
                    const errorMessage = error.message;
                    this.setState({ fail: true, failText: "Error occured. Tell Keith this: " + errorMessage })
                }else{
                    this.setState({ fail: true, failText: "Email and password not recognised" })
                }
            });
    }


    render() {
        const error = this.state.fail ? <p className="LoginErr">{this.state.failText}</p> : null;
        return (
            <div className="LoginContainer">
                <div className="Logo"><h1 className="AdminLogo">Tom Cole</h1></div>
                <input type="text" placeholder="Username" onChange={(event) => this.changeHandler(event, "user")} />
                <input type="password" placeholder="Password" onChange={(event) => this.changeHandler(event, "pass")} />
                <button onClick={this.attemptLogin}>LOGIN</button>
                {error}
            </div>
        )
    }

}

export default Login;