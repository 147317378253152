// firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions"

const firebaseConfig = {
  apiKey: "AIzaSyCTVpvOi0itkL0821RvltHahavj2P-f8uw",
  authDomain: "tom-cole-music.firebaseapp.com",
  projectId: "tom-cole-music",
  storageBucket: "tom-cole-music.appspot.com",
  messagingSenderId: "118258544110",
  appId: "1:118258544110:web:3d49176958a614ca1c72ff",
  measurementId: "G-EV3WKW4BWS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

const functions = getFunctions();

export { db, app, functions, httpsCallable };
